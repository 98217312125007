import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, TextField, Box } from '@mui/material';

const Logs = () => {
  const [data, setData] = useState([]);

  const [globalCount, setGlobalCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [count4, setCount4] = useState(0);
  const [count5, setCount5] = useState(0);

  const apiUrl = process.env.REACT_APP_API_URL;
    const today = new Date().toISOString().split('T')[0];
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);

    const [peopleCount, setPeopleCount] = useState(4); // Default people_count is 4
  
  useEffect(() => {
    const fetchData = async () => {
      try {

          let startDateTime = startDate + 'T00:00:00';
          let endDateTime = endDate + 'T23:59:59';


        const response = await axios.get(`${apiUrl}metadata?start_datetime=${startDateTime}&end_datetime=${endDateTime}&people_coun=${peopleCount}`);
        const sortedData = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setData(sortedData);

          // Calcular los contadores
          let global = sortedData.length;
          let count1 = sortedData.filter(item => item.people_count === 1).length;
          let count2 = sortedData.filter(item => item.people_count === 2).length;
          let count3 = sortedData.filter(item => item.people_count === 3).length;
          let count4 = sortedData.filter(item => item.people_count === 4).length;
          let count5 = sortedData.filter(item => item.people_count === 5).length;
  
          setGlobalCount(global);
          setCount1(count1);
          setCount2(count2);
          setCount3(count3);
          setCount4(count4);
          setCount5(count5);


      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', justifyContent: 'center'}}>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '80%', marginBottom: 4, marginTop: 10 }}>
              <TextField
                  label="Date de début"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                      shrink: true,
                  }}
              />
              <TextField
                  label="Date de fin"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{
                      shrink: true,
                  }}
              />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '80%', marginBottom: 4 }}>
        <div>Total Global: {globalCount}</div>
        <div>Nombre 1: {count1}</div>
        <div>Nombre 2: {count2}</div>
        <div>Nombre 3: {count3}</div>
        <div>Nombre 4: {count4}</div>
        <div>Nombre 5: {count5}</div>
      </Box>
    <TableContainer className='log-container' component={Paper} style={{ height: '100vh', width: '100vw' }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell className='log-header'>Date</TableCell>
            <TableCell className='log-header'>Heure</TableCell>
            <TableCell className='log-header'>Participants</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => {
            const dateTimeString = item.created_at;
            const fecha = dateTimeString.slice(0, 10);            
            const hora = dateTimeString.slice(11, 19);
            return(
              <TableRow key={index}>
              <TableCell className='log-row'>{fecha}</TableCell>
              <TableCell className='log-row'>{hora}</TableCell>
              <TableCell className='log-row'>{item.people_count}</TableCell>
            </TableRow>
            );            
          })}
        </TableBody>
      </Table>
    </TableContainer>
      </Box>
  );
};

export default Logs;
