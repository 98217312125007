import React, { useState, useEffect } from 'react';
import logoUrl from '../assets/img/LogoJO&Tourtel_DOUBLE_HORIZONTAL_RVB.png';

const CardComponent = ({ imageUrl, className }) => {
    const combinedClassName = `card ${className}`;
    return (
        <div className={combinedClassName}>
            <img src={imageUrl} alt="Imagen principal" className="card-image" />
            <div className="card-logo-container">
                <img src={logoUrl} alt="Logotipo" className="card-logo" />
            </div>
        </div>
    );
};

export default CardComponent;
