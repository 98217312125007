import React, { createContext, useContext, useState } from 'react';


const AppContext = createContext();


export function useAppContext() {
  return useContext(AppContext);
}


export function AppProvider({ children }) {
  const [images, setImages] = useState([]);
  const [activeStep, setActiveStep] = useState(1);
  const [takePhoto, setTakePhoto] = useState(1);
  const [error, setError] = useState('');

  return (
    <AppContext.Provider value={{ images, setImages, activeStep, setActiveStep, takePhoto, setTakePhoto, error, setError }}>
      {children}
    </AppContext.Provider>
  );
}
